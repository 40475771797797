.sans {
  font-family: Avenir Next, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  font-weight: 400;
}

h1,
h2,
h3 {
  margin-bottom: 0 !important
}

.cu-unit a {
  color: unset;
  text-decoration: none;
}

.cu-unit a:hover {
  text-decoration: underline;
}

.cu-seal .cu-logo a {
  height: 90px;
  min-width: 90px;
  width: 90px;
}

.cu-logo {
  display: flex;
  justify-content: center;
}

#cu-header .cu-brand {
  padding-top: 15px;
  padding-bottom: 15px;
}

#cu-header .cu-brand h2 {
  margin: 6px 0 0 -.05em;
  line-height: 1.2;
}

#cu-header .cu-brand .cu-unit {
  margin: 6px 0 0 -.05em;
  text-align: center;
  width: 100%;
}

.cu-seal #cu-header {
  background: linear-gradient(135deg, #f2f2f2, #f7f7f7);
  border-bottom: 2px solid #ebebeb;
  z-index: 3;
}

.cu-seal .cu-logo a {
  background-image: url('../../assets/images/bold_cornell_seal_simple_white.svg');
}

.cu-seal.cu-gray #cu-header {
  background: linear-gradient(135deg, #222 0%, #474747 100%);
  color: #fff;
  border-bottom: 0;
}

.cu-seal .cu-logo a {
  height: 90px;
  min-width: 90px;
  width: 90px;
}

@media (min-width: 868px) {
  .cu-seal #cu-header .cu-brand {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  #cu-header .cu-brand .cu-unit {
    width: fit-content;
  }

  #cu-header .cu-brand h3 {
    margin-top: 0.3em;
    // color: #6a6a79; overridden in global
    font-weight: 400;
    font-size: 18px;
  }


  .cu-seal .cu-logo a {
    height: 100px;
    margin: 0 50px 0 0 !important;
    min-width: 100px;
    width: 100px;
  }

  #cu-header .cu-brand .cu-unit {
    margin: 0 70px 0 auto;
    text-align: left;
  }

  .cu-logo {
    justify-content: flex-start;
  }

  .cu-logo {
    flex: 1;
  }
}

@media (min-width: 1200px) {
  .cu-seal .cu-logo a {
    height: 120px;
    margin: 0 60px 0 0 !important;
    min-width: 120px;
    width: 120px;
  }

  #cu-header .cu-brand h2 {
    font-size: 36px;
  }
}

.cu45-helper {
  background-color: #b31b1b;
  height: 52px;
  position: relative;

}

// .cu45-helper {
//   min-height: 41px;
//   background-color: #b31b1b;
// }

#cu-brand {
  background-image: url("/assets/images/45pxlogo-white.svg");
  background-size: 34px 34px;
  background-repeat: no-repeat;
  display: inline-block;
  margin-top: 9px;
  margin-left: 1em;
  vertical-align: middle;
  padding-left: 45px;
  height: 34px;
}

.cu-logotype {
  background: url("/assets/images/logotype-white.svg") no-repeat;
  background-size: 139px 18px;
  width: 139px;
  height: 18px;
  position: relative;
  text-indent: -9999em;
  top: 10px;
  color: #fff;
  background-color: transparent;
}

.cu-ribbon {
  border-bottom: 4px solid #b31b1b;
}

/* mobile only */
@media (max-width: 868px) {}

/* desktop only */
@media (max-width: 868px) {
  .cu-ribbon {
    display: none;
  }

  .cu-logo {
    display: none;
  }

}
