.main-footer {
  padding: 45px 0;

  a {
    text-decoration: none;
    transition: color 0.2s, -moz-text-decoration-color 0.2s, -webkit-text-decoration-color 0.2s, text-decoration-color 0.2s;
    color: #bbb;
    text-decoration-color: rgba(255, 255, 255, 0.4);

    &:focus,
    &:hover {
      text-decoration: underline;
      color: #fff;
      text-decoration-color: white;
      transition: none;
    }

    &:active {
      color: #9b9b9b;
      text-decoration-color: rgba(255, 255, 255, 0.4);
    }
  }

  .h6,
  .h5 {
    margin-top: 0;
    margin-bottom: 1em;
    line-height: 1.4;
    font-family: "freight-sans-pro", sans-serif;
    color: #eee;
  }

  .h5 {
    margin-top: 0.3em;
    font-size: 20px;
    font-weight: 400;
  }

  .h6 {
    text-transform: uppercase;
    margin-top: 0.5em;
    font-size: 16px;
    font-weight: 600;
  }

  .footer-links {
    li {
      line-height: 1.4;
    }
  }


  ul {
    li {
      font-size: 15px;
    }
  }


  .footer-links {
    margin-bottom: 0;
  }

  .list-menu {
    padding: 0;
    margin: 0 0 1.5em;
    list-style: none;
    // float: left;
    width: 100%;
    clear: both;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      // float: left;
      display: block;
      background: none !important;
      padding: 0 0.6em 0 0;
      margin: 0 0.6em 0.6em 0;
      border-width: 0 1px 0 0;

      &:last-child {
        border-right-width: 0;
      }
    }
  }

  .list-menu.vertical {
    li {
      //float: none; /* same as full-width */
      width: 100%;
      padding: 0.2em 0;
      margin: 0;
      border-width: 0 0 1px 0;

      &:first-child {
        border-top: 1px solid #4c4c4c;
      }
    }
  }
}

@media (min-width: 768px) {

  .main-footer {
    .secondary {
      border-right: 1px solid #5e5e5e;
      padding-right: 16px;
    }

  }
}
