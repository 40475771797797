.sub-footer {
  line-height: 1.6;
  color: #bbb;
  font-size: 13px;
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  display: block;
  background: #292929;
  padding: 20px 0;
}

.vertical-align {
  display: flex;
  align-items: center;
}

p {
  margin-top: 0;
  margin-bottom: 1.5em;

  &:last-child {
    margin-bottom: 0;
  }
}

.sub-footer a {
  color: #fff;
  text-decoration-color: hsla(0, 0%, 100%, .4);

  &:focus {
    color: #fff;
    text-decoration-color: #fff;
  }

  &:hover {
    color: #fff;
    text-decoration-color: #fff;
  }

  &:active {
    color: #666;
    text-decoration-color: hsla(0, 0%, 100%, .4);
  }
}


.icon {
  margin-right: 12px;
  color: #292929;
  position: relative;
  overflow: initial !important
}

.icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28px;
  height: 28px;
  background-color: #bbb;
  /* This is the new background color */
  border: #292929 solid 1px;
  /* Creates a thin border */
  border-radius: 50%;
  /* Makes the background circular */
  transform: translate(-50%, -50%);
  /* Centers the background */
  z-index: -1;
  /* Places the background behind the icon */
  box-shadow: 0 0 0 2px #bbb;
  /* Creates the outer circle */
}



// @media (max-width: $screen-sm-min) {
@media (max-width: 767px) {
  .icon {
    display: none !important
  }

  .icon::before {
    display: none;
  }
}
